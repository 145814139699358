import React, { useEffect, useState } from 'react';
import {Button, Modal} from "@mui/material";
import {useSelector} from "react-redux";
import Image from "next/image";
import {useTranslation} from "next-i18next";

export default function AgeVerification() {
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [isValidAge, setIsValidAge] = useState(true);
  const user = useSelector(state => state.auth.user)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const ageChecked = JSON.parse(localStorage.getItem('ageChecked'));
    setOpen(!Boolean(ageChecked));
    setLoading(false)
  }, []);

  useEffect(() => {
    if (user?.isLoggedIn) {
      handleYes()
    }
  }, [user])

  const handleYes = () => {
    localStorage.setItem('ageChecked', 'true');
    setOpen(false)
  };

  const handleNo = () => {
    setIsValidAge(false);
  };


  return (
    <>
      {loading && <div className="fixed z-[1299] bg-gray-100 inset-0"></div>}
      <Modal
        open={open}
        onClose={e => e.preventDefault()}
        className={'flex p-6'}
        componentsProps={{
          backdrop: {
            className: "bg-gray-100"
          }
        }}
      >
        <div className="bg-white m-auto shadow-lg p-8 rounded max-w-md outline-0 w-full flex flex-col items-center text-center">
          <div className="max-w-[250px] mb-6">
            <Image src={require('assets/logo-new.png')} alt="DaMaMaps"/>
          </div>
          {
            isValidAge ? (
              <>
                <div className="text-3xl font-semibold mb-4">
                  {t('home:areYouOldEnough')}
                </div>
                <div className="text-gray-500 mb-10">
                  {t('home:ageVerificationNote')}
                </div>
                <div className="flex w-full gap-4">
                  <Button variant="outlined" fullWidth onClick={handleNo}>
                    {t('home:notOldEnough')}
                  </Button>
                  <Button className={'!bg-[#108085]'} variant="contained" fullWidth onClick={handleYes}>
                    {t('home:oldEnough')}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="text-3xl font-semibold mb-4">
                  {t('notOldEnoughRespond')}
                </div>
                <div className="text-gray-500">
                  {t('notOldEnoughRespond2')}
                </div>
              </>
            )
          }
        </div>
      </Modal>
    </>
  )
}
