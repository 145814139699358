import {twMerge} from 'tailwind-merge'

export default function Wrapper({children, className, ...props}) {
  return (
    <div
      {...props}
      className={twMerge('py-12', className)}
    >
      {children}
    </div>
  )
}
