import React, {createRef, useEffect, useState} from "react";
import Wrapper from 'components/blocks/Wrapper'
import Container from "components/blocks/Container";
import Image from 'next/image'
import Link from 'next/link'
import cn from 'clsx'
import {useRouter} from 'next/router'
import {checkMobile} from '../../../utils/helper'
import styles from './index.module.scss'
import Logo from 'assets/logo-new.png'
import UserMenu from "components/blocks/Header/UserMenu";
import {useTranslation} from "next-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FormControl, OutlinedInput} from "@mui/material";
import {useDispatch} from "react-redux";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Drawer from "@mui/material/Drawer";
import {getProductCategories} from "lib/services/product";
import {wrapper} from "lib/store";
import NavMenu from '../../NavMenu'

import config from '../../../data/config.json'

export default function Header(props) {
  const {
    categories
  } = props
  const router = useRouter()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [menuAnchor, setMenuAnchor] = React.useState(null)
  const menuRef = createRef()
  const menuOpen = Boolean(menuAnchor)

  const openLangMenu = event => {
    setMenuAnchor(event.currentTarget)
  }
  const closeLangMenu = () => {
    setMenuAnchor(null)
  }

  const changeLang = async () => {
    console.log('t: ', i18n.language, router.pathname)
    const { pathname, asPath, query } = router
    await router.push({ pathname, query }, asPath, { locale: i18n.language === 'en' ? 'cn' : 'en' })
  }

  const ROUTES = [{
    href: '/',
    label: t('home:Home'),
  }, {
    href: '/products',
    label: t('home:Products'),
  },
// , {
//   href: '/#',
//   label: t('home:Strains'),
// },
    {
      href: '/brands/discovery',
      label: t('home:Brands'),
    }, {
      href: '/services',
      label: t('home:Services'),
    }, {
      href: '/map',
      label: t('home:Map'),
    },
// {
//   href: '/deals',
//   label: t('home:Deals'),
// }
  ]
  const [searchInput, setSearchInput] = useState('')
  const handleChangeSearchInput = (e) => {
    setSearchInput(e.target.value)
  }
  const handleSearch = async (e) => {
    await router.push(`/search/${searchInput}`)
  }

  const goToHome = async (e) => {
    await router.push(`/`)
  }

  const goTo = async (parentRoute, routeName) => {
    console.log('routerName: ', routeName)
    if (routeName === t('home:allCategories')) {
      await router.replace(`${parentRoute.href}`)
    } else {
      await router.replace(`${parentRoute.href}/${routeName.toLowerCase()}`)
    }
  }

  const goToBag = async (e) => {
    await router.push(`/shoppingBag`)
  }

  const [mobileOpen, setMobileOpen] = useState(false);
  const [loaded, setLoaded] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (loaded) {
      setIsMobile(checkMobile())
    }
  }, [loaded])

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawerWidth = 240;
  const drawer = (
      <Box sx={{ textAlign: 'center' }}>
        <div className="mx-5 my-2">
          <Image className={styles.logo} src={Logo} alt="Logo" onClick={goToHome}/>
        </div>
        <Divider />
        <List>
          {ROUTES.map((route) => (
              route.label === t('home:Products') ?
                  <NavMenu isMobile={isMobile} key={route.label} clickAction={goTo} t={t} routeInfo={route} menuName={t('home:Products')} menuList={config.product.categories} /> :
              <ListItem key={route.label} disablePadding>
                <ListItemButton  className="flex justify-center" sx={{ textAlign: 'center' }}>
                  <Link href={route.href} key={route.label}>
                    <a className={cn(
                        'font-bold', 'mr-7', 'last:mr-0', 'leading-10', 'uppercase',
                        {'text-primary-dark': router.pathname === route.href}
                    )}>
                      {route.label}
                    </a>
                  </Link>
                </ListItemButton>
              </ListItem>
          ))}
          <ListItem>
            <ListItemButton className={cn('w-10', 'flex', 'justify-center')} onClick={changeLang}
                    aria-controls={menuOpen ? 'lang-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? 'true' : undefined}
            >
              {
                i18n.language === 'en' ?
                    <span className={cn(
                        'font-bold', 'mr-7', 'last:mr-0', 'leading-10', 'uppercase')}>{'中文'}</span> :
                    <span className={cn(
                        'font-bold', 'mr-7', 'last:mr-0', 'leading-10', 'uppercase')}>{'EN'}</span>
              }
            </ListItemButton>
          </ListItem>
          <ListItem>
            <FormControl fullWidth size={'small'} className={'mt-1 w-auto'}>
              <OutlinedInput
                  placeholder={t('home:searchThisMenu')}
                  id={'menu-search'}
                  onChange={handleChangeSearchInput}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      // Do code here
                      handleSearch()
                      ev.preventDefault();
                    }
                  }}
                  endAdornment={
                    <FontAwesomeIcon className={'cursor-pointer'} icon={solid('search')} onClick={handleSearch} />
                  }
              />
            </FormControl>
          </ListItem>
          <ListItem>
          </ListItem>
        </List>
      </Box>
  );

  return (
      <header className={cn(styles.header, 'sticky', 'top-0', 'bg-white', 'z-40')}>
        <Wrapper className="shadow-md py-4">
          <Container className={cn(styles.container, 'flex', 'items-center', 'flex-nowrap')}>
            <div className={cn(styles.logo, 'mr-7', 'shrink-0')}>
              <Image className={styles.logo} src={Logo} alt="Logo" onClick={goToHome}/>
            </div>
            {
              isMobile ?
                  <div className="flex w-full justify-end">
                    <UserMenu t={t}/>
                    <Toolbar className="flex flex-row align-center self-center  pr-0">
                      <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          edge="start"
                          onClick={handleDrawerToggle}
                          sx={{ display: { sm: 'none' } }}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Toolbar>
                    <Box component="nav">
                      <Drawer
                          // container={container}
                          variant="temporary"
                          open={mobileOpen}
                          onClose={handleDrawerToggle}
                          ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                          }}
                          sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                          }}
                      >
                        {drawer}
                      </Drawer>
                    </Box>
                  </div> :
                  <>
                    <nav className={cn('flex', 'grow', 'shrink', 'min-w-0', 'overflow-hidden')}>
                      {
                        ROUTES.map(route => (
                            route.label === t('home:Products') ?
                                <NavMenu isMobile={isMobile} key={route.label} clickAction={goTo} t={t} routeInfo={route} menuName={t('home:Products')} menuList={config.product.categories} /> :
                                <Link href={route.href} key={route.label}>
                                  <a className={cn(
                                      'font-bold', 'mr-7', 'last:mr-0', 'leading-10', 'uppercase',
                                      {'text-primary-dark': router.pathname === route.href}
                                  )}
                                  >
                                    {route.label}
                                  </a>
                                </Link>
                        ))
                      }

                    </nav>
                    <FormControl fullWidth size={'small'} className={'mt-1 w-auto'}>
                      <OutlinedInput
                          placeholder={t('home:searchThisMenu')}
                          id={'menu-search'}
                          onChange={handleChangeSearchInput}
                          onKeyDown={async (ev) => {
                            if (ev.key === 'Enter') {
                              // Do code here
                              await handleSearch()
                              ev.preventDefault();
                            }
                          }}
                          endAdornment={
                            <FontAwesomeIcon className={'cursor-pointer'} icon={solid('search')} onClick={handleSearch} />
                          }
                      />
                    </FormControl>
                    <button className={cn(styles.action, 'w-10', 'flex')} onClick={changeLang}
                            aria-controls={menuOpen ? 'lang-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={menuOpen ? 'true' : undefined}
                    >
                      {
                        i18n.language === 'en' ?
                            <span>{'中文'}</span> :
                            <span>{'EN'}</span>
                        // <Image src={enIcon} alt={'English'} className={'object-cover h-full'} /> :
                        // <Image src={cnIcon} alt={'Chinese'} className={'object-cover h-full'} />
                      }
                    </button>
                    {/*<button className={cn(styles.action, styles.user)}*/}
                    {/*        onClick={goToBag}*/}
                    {/*        aria-controls={menuOpen ? 'auth-menu' : undefined}*/}
                    {/*        aria-haspopup="true"*/}
                    {/*        aria-expanded={menuOpen ? 'true' : undefined}>*/}
                    {/*  <FontAwesomeIcon icon={solid('cart-shopping')} />*/}
                    {/*</button>*/}

                    <UserMenu t={t}/>
                  </>
            }
          </Container>
        </Wrapper>
      </header>
  )
}
