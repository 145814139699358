import cn from "clsx";
import styles from "components/blocks/Header/index.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Link from "next/link";
import {Modal} from "@mui/material";
import LoginForm from "components/forms/LoginForm";
import React, {createRef, useEffect} from "react";
import {useRouter} from "next/router";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "lib/features/authSlice";
import { isValidEmail } from 'utils/helper'

export default function UserMenu({t}) {
  const router = useRouter()
  const dispatch = useDispatch()

  const [menuAnchor, setMenuAnchor] = React.useState(null)
  const [loginModalOpen, setLoginModalOpen] = React.useState(false)
  const user = useSelector(state => state.auth.user)
  const menuRef = createRef()
  const menuOpen = Boolean(menuAnchor)

  const openUserMenu = event => {
    setMenuAnchor(event.currentTarget)
  }
  const closeUserMenu = () => {
    setMenuAnchor(null)
  }

  const handleLogin = () => {
    closeUserMenu()
    router.pathname !== '/login' && setLoginModalOpen(true)
  }

  const closeLoginModal = () => {
    setLoginModalOpen(false)
  }

  const handleLogout = () => {
    closeUserMenu()
    menuRef.current.addEventListener('transitionend', () => {
      dispatch(logout())
    }, {once: true})
  }

  return (
    <>
        {
            !!user
            && isValidEmail(user.email)
                ?
                <button className={cn(styles.action, styles.user)}
                        onClick={openUserMenu}
                        aria-controls={menuOpen ? 'auth-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuOpen ? 'true' : undefined}>
                    <FontAwesomeIcon icon={solid('user')}/>
                </button> :
                <button className={cn(styles.action, styles.user)}
                        onClick={openUserMenu}
                        aria-controls={menuOpen ? 'auth-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuOpen ? 'true' : undefined}>
                    <h4 className="w-25 font-normal text-base">
                        {t('home:notLoggedIn')}
                    </h4>
                </button>
        }
      <Menu id="auth-menu" ref={menuRef}
            anchorEl={menuAnchor} open={menuOpen} onClose={closeUserMenu}
            MenuListProps={{'aria-labelledby': 'basic-button'}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
      >
        {(!user || !isValidEmail(user.email)) ? [
            <MenuItem key='login' onClick={handleLogin} className={'hover:text-primary'}>Log in</MenuItem>,
            <Divider key='d-1'/>,
            <Link key='sign-up' href={'/sign-up'}>
              <MenuItem onClick={closeUserMenu} className={'hover:text-primary'}>Sign up</MenuItem>
            </Link>
        ] : [
            <MenuItem key={'user-info'} disabled>Logged in as <br/>{user.email}</MenuItem>,
            <Divider key={'d-1'}/>,
            <Link key={'change-password'} href={'/change-password'}>
              <MenuItem onClick={closeUserMenu} className={'hover:text-primary'}>Change password</MenuItem>
            </Link>,
            <MenuItem key={'logout'} onClick={handleLogout} className={'hover:text-primary'}>Log out</MenuItem>
        ]}
          {
              !!user && !!isValidEmail(user.email) ?
                  <>
                  <Divider key={'d-1'}/>
                  <a  rel="noreferrer"  key={'change-password'} href={'https://admin.damamaps.com/dashboard/business-account'} target='_blank' >
                      <MenuItem onClick={closeUserMenu} className={'hover:text-primary'}>Manage my store</MenuItem>
                  </a>
                  </>
              :
                  null
          }
      </Menu>

      <Modal
        open={loginModalOpen}
        onClose={closeLoginModal}
        className={'flex p-6'}
      >
        <div className="bg-white m-auto shadow-lg p-8 rounded max-w-sm">
          <h2 className={'text-3xl font-bold text-center mb-8'}>
            Log in
          </h2>
          <LoginForm onSuccess={closeLoginModal}/>
        </div>
      </Modal>
    </>
  )
}
