import * as React from 'react';
import {useEffect, useState} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import cn from "clsx";
import {useRouter} from "next/router";
import { NestedMenuItem } from 'mui-nested-menu';



export default function NavMenu(props) {
    const { menuName, menuList, routeInfo, t, clickAction, isMobile } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [localList, setLocalList] = useState([])
    const router = useRouter()
    const open = Boolean(anchorEl);

    const handleClick = async (parentRouteInfo, routeName) => {
        handleClose()
        await clickAction(parentRouteInfo, routeName)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHover = (e, name) => {
        if (name === menuName) {
            setAnchorEl(e.currentTarget);
        }
    }

    const handleLeave = (e, name) => {
        if (name === menuName) {
            setAnchorEl(null);
        }
    }



    useEffect(() => {
        console.log('at menu: ', routeInfo.href, isMobile)
        let temp = [...menuList]
        let all = temp.find(item => item.name === t('home:allCategories'))
        let hasAll = all !== undefined
        if (!hasAll) {
            temp.push({
                name: t('home:allCategories'),
                url: '',
                slug: ''
            })
        }
        setLocalList(temp)
    }, [])



    return (
        <div className={isMobile ?
            cn(
                'font-bold', 'last:mr-0', 'leading-10', 'uppercase',
                {'text-primary-dark': router.pathname === routeInfo.href}
            ) :
            cn(
                'font-bold', 'mr-7', 'last:mr-0', 'leading-10', 'uppercase',
                {'text-primary-dark': router.pathname === routeInfo.href}
            )}
        >
            <a className={
                cn(
                    'font-bold', 'mr-7', 'last:mr-0', 'leading-10', 'uppercase',
                )
            }
               onClick={(e) => isMobile ? handleHover(e, routeInfo.label) : null}
               onMouseOver={(e) => isMobile ? null : handleHover(e, routeInfo.label)}
            >
                {menuName}
            </a>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    onMouseLeave: (e) => handleLeave(e, routeInfo.label)
                }}
                className={'mt-5'}
            >
                {
                    localList.map((item, index) => {
                        if (item.subcategories && item.subcategories.length > 0) {
                            return (
                                <NestedMenuItem
                                    key={item.name}
                                    label={t(`home:${item.slug}`)}
                                    parentMenuOpen={open}
                                    onClick={() => isMobile ? null : handleClick(routeInfo, item.slug)}
                                    className={cn(
                                        'mr-7', 'last:mr-0', 'leading-10',
                                    )}
                                >
                                    {
                                        !!item.subcategories && item.subcategories.length > 0 && item.subcategories.map(nestedItem => (
                                            <MenuItem key={nestedItem.name} onClick={() => handleClick(routeInfo, nestedItem.slug)}
                                                      className={cn(
                                                          'last:mr-0', 'leading-10'
                                                      )}
                                            >{t(`home:${nestedItem.slug}`)}</MenuItem>
                                        ))
                                    }
                                </NestedMenuItem>
                            )
                        } else {
                            return (

                                <MenuItem key={item.name} onClick={() => handleClick(routeInfo, item.slug)}
                                          className={cn(
                                              'mr-7', 'last:mr-0', 'leading-10',
                                          )}
                                >
                                    {item.name === t('home:allCategories') ? t('home:allCategories') : t(`home:${item.slug}`)}
                                </MenuItem>
                            )
                        }

                    })
                }
            </Menu>
        </div>
    );
}
