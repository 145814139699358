import {twMerge} from "tailwind-merge";

export default function Container({children, className, ...props}) {
  return (
    <div
      {...props}
      className={twMerge('w-[86.667%]', 'max-w-screen-xl', 'mx-auto', className)}
    >
      {children}
    </div>
  )
}
